<template>
  <div id="contact-wrapper">
    <h2>Sign up for the <a href="https://forms.gle/J3LaRcEGLy8dkxih9">mailing list!</a></h2>

    <h2>Direct Contact</h2>
    <p>parkersexton@gmail.com</p>
    <p>(919)704-6108</p>
  </div>
</template>

<script>
export default {
  name: 'ContactView'
}
</script>

<style scoped>
#contact-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>